// var localStorage = require('localStorage')
let localStorage = window.localStorage;

export default {
  email: null,

  login(email, token) {
    localStorage.setItem("id_token", token);
    localStorage.setItem("email", email);
    this.email = email;
  },

  logout() {
    localStorage.removeItem("id_token");
    localStorage.removeItem("email");
    window.location.reload();
  },

  loggedIn() {
    let jwt = localStorage.getItem("id_token");

    if (jwt) {
      this.email = localStorage.getItem("email");
      return true;
    } else {
      return false;
    }
  },

  getAuthHeader() {
    return "Bearer " + localStorage.getItem("id_token");
  }
};
