import { createStore } from "vuex";
import { CancelToken, isCancel } from "axios";

let loadInvoicingSource = CancelToken.source();
let loadInksSource = CancelToken.source();
let loadManufacturersSource = CancelToken.source();
let loadOrderLogsSource = CancelToken.source();
let loadOrdersSource = CancelToken.source();
let loadThreadsSource = CancelToken.source();
let loadVinylsSource = CancelToken.source();
let loadUsersSource = CancelToken.source();

export default createStore({
  state: {
    currentBusinessId: null,
    manufacturers: [],
    inks: [],
    threads: [],
    vinyls: [],
    orderLogs: [],
    orders: [],
    profile: null,
    users: []
  },
  mutations: {
    currentBusinessSet(state, businessId) {
      state.currentBusinessId = businessId;
    },

    manufacturersClear(state) {
      state.manufacturers = [];
    },

    manufacturersSet(state, manufacturers) {
      state.manufacturers = manufacturers;
    },

    inksClear(state) {
      state.inks = [];
    },

    inksSet(state, inks) {
      state.inks = inks;
    },

    threadsClear(state) {
      state.threads = [];
    },

    threadsSet(state, threads) {
      state.threads = threads;
    },

    vinylsClear(state) {
      state.vinyls = [];
    },

    vinylsSet(state, vinyls) {
      state.vinyls = vinyls;
    },

    orderLogsClear(state) {
      state.orderLogs = [];
    },

    orderLogsSet(state, orderLogs) {
      state.orderLogs = orderLogs;
    },

    ordersClear(state) {
      state.orders = [];
    },

    ordersSet(state, orders) {
      state.orders = orders;
    },

    profileSet(state, profile) {
      state.profile = profile;
    },

    usersClear(state) {
      state.users = [];
    },

    usersSet(state, users) {
      state.users = users;
    }
  },
  actions: {
    loadManufacturers({ commit, state }, params = {}) {
      loadManufacturersSource.cancel();
      loadManufacturersSource = CancelToken.source();

      if (!params.query) {
        params.query = "";
      }
      return this.$http
        .get(
          `api/v1/businesses/${state.currentBusinessId}/manufacturers/` + params.query,
          {
            cancelToken: loadManufacturersSource.token
          }
        )
        .then(response => {
          const manufacturers = response.data.results;
          commit("manufacturersClear");
          commit("manufacturersSet", manufacturers);
          response.data.pages = Math.ceil(response.data.count / 10);
          return response.data;
        })
        .catch(e => {
          if (isCancel(e)) {
            return;
          }
          return e;
        });
    },

    loadInks({ commit, state }, params = {}) {
      loadInksSource.cancel();
      loadInksSource = CancelToken.source();

      if (!params.query) {
        params.query = "";
      }
      return this.$http
        .get(
          `api/v1/businesses/${state.currentBusinessId}/inks/` + params.query,
          {
            cancelToken: loadInksSource.token
          }
        )
        .then(response => {
          const inks = response.data.results;
          commit("inksClear");
          commit("inksSet", inks);
          response.data.pages = Math.ceil(response.data.count / 10);
          return response.data;
        })
        .catch(e => {
          if (isCancel(e)) {
            return;
          }
          return e;
        });
    },

    loadThreads({ commit, state }, params = {}) {
      loadThreadsSource.cancel();
      loadThreadsSource = CancelToken.source();

      if (!params.query) {
        params.query = "";
      }
      return this.$http
        .get(
          `api/v1/businesses/${state.currentBusinessId}/threads/` + params.query,
          {
            cancelToken: loadThreadsSource.token
          }
        )
        .then(response => {
          const threads = response.data.results;
          commit("threadsClear");
          commit("threadsSet", threads);
          response.data.pages = Math.ceil(response.data.count / 10);
          return response.data;
        })
        .catch(e => {
          if (isCancel(e)) {
            return;
          }
          return e;
        });
    },

    loadVinyls({ commit, state }, params = {}) {
      loadVinylsSource.cancel();
      loadVinylsSource = CancelToken.source();

      if (!params.query) {
        params.query = "";
      }
      return this.$http
        .get(
          `api/v1/businesses/${state.currentBusinessId}/stock-vinyls/` + params.query,
          {
            cancelToken: loadVinylsSource.token
          }
        )
        .then(response => {
          const vinyls = response.data.results;
          commit("vinylsClear");
          commit("vinylsSet", vinyls);
          response.data.pages = Math.ceil(response.data.count / 10);
          return response.data;
        })
        .catch(e => {
          if (isCancel(e)) {
            return;
          }
          return e;
        });
    },

    loadInvoicing({ commit, state }, params = {}) {
      loadInvoicingSource.cancel();
      loadInvoicingSource = CancelToken.source();

      if (!params.query) {
        params.query = "";
      }
      return this.$http
        .get(
          `api/v1/businesses/${state.currentBusinessId}/orderinvoicing/` +
            params.query,
          {
            cancelToken: loadInvoicingSource.token
          }
        )
        .then(response => {
          const orders = response.data.results;
          commit("ordersClear");
          commit("ordersSet", orders);
          response.data.pages = Math.ceil(response.data.count / 10);
          return response.data;
        })
        .catch(e => {
          if (isCancel(e)) {
            return;
          }
          return e;
        });
    },

    loadOrderLogs({ commit, state }, params = {}) {
      loadOrderLogsSource.cancel();
      loadOrderLogsSource = CancelToken.source();

      if (!params.query) {
        params.query = "";
      }
      return this.$http
        .get(
          `api/v1/businesses/${state.currentBusinessId}/orderlogs/` + params.query,
          {
            cancelToken: loadOrderLogsSource.token
          }
        )
        .then(response => {
          const orderLogs = response.data.results;
          commit("orderLogsClear");
          commit("orderLogsSet", orderLogs);
          response.data.pages = Math.ceil(response.data.count / 10);
          return response.data;
        })
        .catch(e => {
          if (isCancel(e)) {
            return;
          }
          return e;
        });
    },

    loadOrders({ commit, state }, params = {}) {
      loadOrdersSource.cancel();
      loadOrdersSource = CancelToken.source();

      if (!params.query) {
        params.query = "";
      }
      return this.$http
        .get(
          `api/v1/businesses/${state.currentBusinessId}/orders/` + params.query,
          {
            cancelToken: loadOrdersSource.token
          }
        )
        .then(response => {
          const orders = response.data.results;
          commit("ordersClear");
          commit("ordersSet", orders);
          response.data.pages = Math.ceil(response.data.count / 10);
          return response.data;
        })
        .catch(e => {
          if (isCancel(e)) {
            return;
          }
          return e;
        });
    },

    loadOrder({ commit }, id) {
      return this.$http.get(`api/v1/orders/${id}/`).then(response => {
        const order = response.data;
        commit("ordersClear");
        commit("ordersSet", [order]);
      });
    },

    loadProfile({ commit, state }) {
      return this.$http
        .get(`api/v1/businesses/${state.currentBusinessId}/profile/`)
        .then(response => {
          const profile = response.data;
          commit("profileSet", profile);
        });
    },

    loadUsers({ commit, state }, params = {}) {
      loadUsersSource.cancel();
      loadUsersSource = CancelToken.source();

      if (!params.query) {
        params.query = "";
      }
      return this.$http
        .get(
          `api/v1/businesses/${state.currentBusinessId}/users/` + params.query,
          {
            cancelToken: loadUsersSource.token
          }
        )
        .then(response => {
          const users = response.data.results;
          commit("usersClear");
          commit("usersSet", users);
          response.data.pages = Math.ceil(response.data.count / 10);
          return response.data;
        })
        .catch(e => {
          if (isCancel(e)) {
            return;
          }
          return e;
        });
    }
  },
  modules: {}
});
