<template>
  <Menu v-if="menuIsVisible"></Menu>
  <router-view />
</template>

<script>
import auth from "@/auth";
import Menu from "@/components/Menu";
import { mapActions } from "vuex";

export default {
  components: {
    Menu
  },

  computed: {
    menuIsVisible() {
      return (
        this.$route.name &&
        this.$route.name !== "Login" &&
        this.$route.name !== "LoginMicrosoft" &&
        this.$route.name !== "Proof"
      );
    }
  },

  methods: {
    ...mapActions(["loadProfile"])
  },

  created() {
    if (auth.loggedIn()) {
      this.loadProfile();
    }
  }
};
</script>

<style>
h1,
h2,
h3,
h4 {
  font-family: HelveticaNeueCondensedBold;
}

@font-face {
  font-family: "HelveticaNeueCondensedBold";
  src: url("../public/HelveticaNeueCondensedBold.ttf") format("truetype");
}

td.blue,
td.orange,
td.green {
  font-weight: bold;
}
</style>
