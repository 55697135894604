window.jQuery = window.$ = require("jquery");
require("fomantic-ui-css/semantic.min.js");
import "fomantic-ui-css/semantic.min.css";
import { createApp } from "vue";
import App from "./App.vue";
import auth from "./auth";
import axios from "axios";
import router from "./router";
import store from "./store";

const app = createApp(App)
  .use(store)
  .use(router);

window.app = app;

// Point to the correct API and use the correct Business id
let baseURL

if (process.env.NODE_ENV === "development") {
  // Use your local API docker container
  baseURL = "http://localhost:8000/";
  // To use the Production API uncomment this line
  // baseURL = "https://api.om.pixelshive.com/";
  // If you uncommented it, change this to the production Business id
  // store.state.currentBusinessId = "d560f8c4-ac04-49f1-a73c-d8b26b18c0af";
  store.state.currentBusinessId = "7079581e-c69e-42f4-bb95-608c7ed11230";
} else {
  // Same API URL for all webclient domains
  baseURL = "https://api.om.pixelshive.com/";

  // The selected Business depends on the domain
  switch (window.location.hostname) {
    // Select the business and image assets and colors
    // Image assets and colors selection isn't implemented
    case "om.pixelshive.com":
      // Points to The Identity Source
      /* falls through */
    case "art.identitysource.com":
      // This id is the Business id of The Identity Source in production
      store.state.currentBusinessId = "d560f8c4-ac04-49f1-a73c-d8b26b18c0af";
      break;
    }
}

const $http = axios.create({
  baseURL
});

$http.interceptors.request.use(
  function(config) {
    // This function helps test the GET retrier (see response interceptor)
    // by simulating random failures
    // let odds = Math.random()
    // if (process.env.NODE_ENV === 'development') {
    //   if (odds > 0.5) {
    //     return Promise.reject({ config })
    //   }
    // }

    if (auth.loggedIn()) {
      config.headers["Authorization"] = auth.getAuthHeader();
    }

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

$http.interceptors.response.use(function (config) {
  return config
}, function (error) {
  // If a GET response fails, retry in 500ms
  // GET responses aren't supposed to alter state so there
  // should be no danger on retrying a GET
  if (!error.response && error.config.method === 'get') {
    return new Promise(resolve => {
      setTimeout(() => resolve($http.get(error.config.url, { params: error.config.params })), 500)
    })
  } else if (error.response && error.response.status === 401) {
    // If the server response is Unauthorized, token is expired
    auth.logout()
  } else {
    throw error
  }
})

app.config.globalProperties.$http = $http;

store.$http = $http;

app.mount("#app");
