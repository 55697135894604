import auth from "../auth/";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue")
  },
  {
    path: "/auth-login",
    name: "LoginMicrosoft",
    component: () =>
      import(
        /* webpackChunkName: "login-microsoft" */ "../views/LoginMicrosoft.vue"
      )
  },
  {
    path: "/invoicing",
    name: "Invoicing",
    component: () =>
      import(/* webpackChunkName: "invoicing" */ "../views/Invoicing.vue")
  },
  {
    path: "/manufacturers/:id",
    name: "Manufacturer",
    component: () =>
      import(/* webpackChunkName: "manufacturer" */ "../views/Manufacturer.vue")
  },
  {
    path: "/manufacturers",
    name: "Manufacturers",
    component: () =>
      import(
        /* webpackChunkName: "manufacturers" */ "../views/Manufacturers.vue"
      )
  },
  {
    path: "/inks/:id",
    name: "Ink",
    component: () =>
      import(/* webpackChunkName: "ink" */ "../views/Ink.vue")
  },
  {
    path: "/inks",
    name: "Inks",
    component: () =>
      import(
        /* webpackChunkName: "inks" */ "../views/Inks.vue"
      )
  },
  {
    path: "/threads/:id",
    name: "Thread",
    component: () =>
      import(/* webpackChunkName: "thread" */ "../views/Thread.vue")
  },
  {
    path: "/threads",
    name: "Threads",
    component: () =>
      import(
        /* webpackChunkName: "threads" */ "../views/Threads.vue"
      )
  },
  {
    path: "/vinyls/:id",
    name: "Vinyl",
    component: () =>
      import(/* webpackChunkName: "vinyl" */ "../views/Vinyl.vue")
  },
  {
    path: "/vinyls",
    name: "Vinyls",
    component: () =>
      import(
        /* webpackChunkName: "vinyls" */ "../views/Vinyls.vue"
      )
  },
  {
    path: "/order-logs",
    name: "OrderLogs",
    component: () =>
      import(
        /* webpackChunkName: "order-logs" */ "../views/OrderLogs.vue"
      )
  },
  {
    path: "/orders/:id",
    name: "Order",
    component: () =>
      import(/* webpackChunkName: "order" */ "../views/Order.vue")
  },
  {
    path: "/orders",
    name: "Orders",
    component: () =>
      import(/* webpackChunkName: "orders" */ "../views/Orders.vue")
  },
  {
    path: "/proofs/:id",
    name: "Proof",
    component: () =>
      import(/* webpackChunkName: "proof" */ "../views/Proof.vue")
  },
  {
    path: "/users/:id",
    name: "BusinessUser",
    component: () =>
      import(
        /* webpackChunkName: "business-user" */ "../views/BusinessUser.vue"
      )
  },
  {
    path: "/users",
    name: "BusinessUsers",
    component: () =>
      import(
        /* webpackChunkName: "business-users" */ "../views/BusinessUsers.vue"
      )
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "Dashboard" }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (
    to.name !== "Login" &&
    to.name !== "LoginMicrosoft" &&
    to.name !== "Proof" &&
    !auth.loggedIn()
  )
    next({ name: "Login" });
  else if (to.name === "Login" && auth.loggedIn()) next({ name: "Dashboard" });
  else next();
});

export default router;
