<template>
  <div class="ui text menu">
    <div class="item">
      <img src="@/assets/logo.png" />
    </div>
    <router-link :to="{ name: 'Dashboard' }" class="item">
      <i class="bars icon"></i>
      DASHBOARD
    </router-link>
    <div class="item">Logged in as {{ profile?.user.email }}</div>
    <a class="item" @click="logout">Logout</a>
    <div
      class="ui right item"
      style="font-family: 'HelveticaNeueCondensedBold'; font-size: 80%;"
    >
      powered by
      <img
        style="vertical-align: middle;width: 60px"
        src="@/assets/pixels.png"
      />
    </div>
  </div>
</template>

<script>
import auth from "@/auth";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["profile"])
  },

  methods: {
    logout() {
      auth.logout();
      this.$router.push({ name: "Login" });
    }
  }
};
</script>

<style scoped>
.ui.text.menu {
  color: #4e5356 !important;
  background-color: #c3cfd8;
  margin: 0 0 2em 0;
}

.ui.text.menu a.item {
  font-weight: bold;
}
</style>
